<template>
    <div class="swiper-wrap">
        <swiper
            @swiper="onSwiper"
            :slides-per-view="1"
            :allow-touch-move="false"
            :loop="true"
            :modules="modules"
            :speed="1000"
            :autoplay="{
            delay: 4000,
        }"
        >
            <swiper-slide v-for="(item, index) in items" :key="index">
                <div class="bg">
                    <img v-if="item.image" :src="item.image" alt="Image" />
                </div>
                <div class="container d-flex align-items-center">
                    <div class="content">
                        <h1>{{ item.title }}</h1>
                        <div class="p" v-html="item.description"></div>

                        <a v-if="item.button_text"
                           :href="item.button_link" class="base-btn"><span>{{ item.button_text }}</span></a>

                    </div>
                </div>
            </swiper-slide>

        </swiper>
    </div>

    <div class="nav-btns">
        <div class="container d-flex justify-content-end gap-3">
            <button type="button" @click="slidePrev" class="prev-slide">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14 8H2M9.33333 3.33334L14 8L9.33333 3.33334ZM14 8L9.33333 12.6667L14 8Z"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>

            <button type="button" @click="slideNext" class="next-slide">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14 8H2M9.33333 3.33334L14 8L9.33333 3.33334ZM14 8L9.33333 12.6667L14 8Z"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";

export default {
    name: "HeroSlider",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
    },

    setup() {
        return {
            modules: [Autoplay],
        };
    },

    data() {
        return {
            // items: this.items,
            swiper: null,
        };
    },

    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },

        slidePrev() {
            this.swiper.slidePrev();
        },
        slideNext() {
            this.swiper.slideNext();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "resources/sass/abstracts/_variables.scss";
@import "resources/sass/abstracts/_mixins.scss";

.nav-btns {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding-bottom: 50px;
    button {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: rgba($white, 0.15);
        svg path {
            transition: all 0.3s ease-in-out;
        }
        &.prev-slide svg {
            transform: scaleX(-1);
        }
        &:hover {
            background-color: $secondary-color;
            box-shadow: 0 12px 28px 0 rgba($secondary-color, 0.39);
            svg path {
                stroke: #0d1034;
            }
        }
    }
}

.swiper {
    min-height: 100vh;
    height: 100vh;
    .container {
        height: 100%;
        /*@media (max-width: 992px) {*/
            /*    height: auto;*/
            padding: 200px 0;
        /*}*/
    }

    .swiper-slide {
        min-height: 100vh !important;
        height: auto !important;
    }
}

.content {
    max-width: 610px;
    @media (min-width: 992px) {
        transform: translateY(50px);
    }
}

h1 {
    color: $white;
    @include fluid-font($min-vw, $max-vw, 35px, 128px);
    line-height: 0.9;
    font-weight: 700;
}

.p {
    opacity: 0.5;
    margin: 15px 0 50px 0;

    * {
        color: $white !important;
        font-size: rem(20px);
        line-height: 1.65;
        font-weight: 500;
    }
}
</style>
