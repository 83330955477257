<template>
    <div class="swiper-wrap">
        <!--            :effect="'fade'"-->
        <swiper
            @swiper="onSwiper"
            :slides-per-view="1"
            :allow-touch-move="true"
            :loop="true"
            :modules="modules"
            :speed="1000"

            :autoplay="{
            delay: 3000,
        }"
        >
            <swiper-slide v-for="(item, index) in items" :key="index">
                <div class="bg">
                    <img :src="item" alt="Image" />
                </div>
            </swiper-slide>

        </swiper>
    </div>
</template>

<script>
// Import Swiper Vue.js components

import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/effect-fade";

export default {
    name: "ProjectImagesSlider",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
    },

    setup() {
        return {
            modules: [Autoplay, EffectFade],
        };
    },

    data() {
        return {
            // items: this.items,
            swiper: null,
        };
    },

    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },

        slidePrev() {
            this.swiper.slidePrev();
        },
        slideNext() {
            this.swiper.slideNext();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "resources/sass/abstracts/_variables.scss";
@import "resources/sass/abstracts/_mixins.scss";

.swiper-wrap {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    &:hover {
        cursor: pointer;
    }

    .swiper {
        min-height: 100%;
        height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        .swiper-slide {
            min-height: 100%;
            height: 100%;
            max-height: 100%;
            width: 100%;
            min-width: 100%;
            max-width: 100%;

            .bg {
                min-height: 100%;
                height: 100%;
                max-height: 100%;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                overflow: hidden;

                img {
                    min-height: 100%;
                    min-width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

</style>
