import "./components/slide-down";
import "./components/contact-form";
import "./components/mobile-menu";
import "./components/header";
import HeroSlider from "./vue-components/HeroSlider.vue";
import ProjectImagesSlider from "./vue-components/ProjectImagesSlider.vue";

import { createApp } from "vue";

const app = createApp();

app.component('hero-slider', HeroSlider);
app.component('project-images-slider', ProjectImagesSlider);
app.mount('main');


// const heroSliderEl = document.querySelector(".hero-slider");
// const heroSlider = createApp();
//
// heroSlider.component("hero-slider", HeroSlider);
//
// if (heroSliderEl) {
//     heroSlider.mount(".hero-slider");
// }
//
//
// const projectImagesEl = document.querySelector(".project-images-slider");
// const projectImages = createApp();
//
// projectImages.component("project-images", ProjectImagesSlider);
//
// if (projectImagesEl) {
//     projectImages.mount(".project-images-slider");
// }
